// Layout actions
export const SET_WIDTH = 'set_width';
export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';
export const PUSH_ALL_MESSAGES = 'PUSH_ALL_MESSAGES';
export const CLEAR_CONNECTIONS = 'CLEAR_CONNECTIONS';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const TOGGLE_VIDEO_PLAYER = 'TOGGLE_VIDEO_PLAYER';
export const SET_CONNECTION_STATUS = 'setConnectionStatus';

// Actions related to authentication
export const AUTH_USER = 'auth_user';
export const AUTH_MFA = 'auth_mfa';
export const AUTH_NEW_PASSWORD_REQUIRED = 'auth_new_password_required';
export const UNAUTH_USER = 'unauth_user';
export const REGISTER_USER = 'register_user';
export const REGISTER_USER_CONFIRM = 'register_user_confirm';
export const REGISTER_MFA = 'register_mfa';
export const REGISTER_USER_ERROR = 'register_user_error';
export const FORGOT_PASSWORD = 'forgot_password';
export const FORGOT_PASSWORD_CONFIRM = 'forgot_password_confirm';
export const AUTH_ERROR = 'auth_error';
export const AUTH_ERROR_CLEAR = 'auth_error_clear';
export const IS_LOADING = 'is_loading';
export const LOGIN_REGISTER_CONFIRMATION = 'login_register_confirmation';
export const FETCH_USER_CHAT_SKILL_HISTORY = 'FETCH_USER_CHAT_SKILL_HISTORY';
export const GET_TENANT = 'GET_TENANT';
export const GET_USER = 'GET_USER';
export const CREATE_TRAY_USER = 'CREATE_TRAY_USER';
export const TRIGGER_PIPELINE = 'TRIGGER_PIPELINE';

// Actions associated with Getting Started Wizard
export const WIZARD_GO_TO_CONNECTORS = 'WIZARD_GO_TO_CONNECTORS';
export const WIZARD_GO_TO_NEW_CONNECTOR_FORM = 'WIZARD_GO_TO_NEW_CONNECTOR_FORM';
export const WIZARD_CREATE_CONNECTOR = 'WIZARD_CREATE_CONNECTOR';
export const WIZARD_UPDATE_PROFILE = 'WIZARD_UPDATE_PROFILE';
export const WIZARD_CHOOSE_STEP = 'WIZARD_CHOOSE_STEP';
export const GET_FEATURED_CONNECTORS = 'GET_FEATURED_CONNECTORS';
export const TEST_CONNECTION = 'TEST_CONNECTION';
export const CLEAR_TESTED_CONNECTION = 'CLEAR_TESTED_CONNECTION';
export const CLEAR_FEATURED_CONNECTORS = 'CLEAR_FEATURED_CONNECTORS';
export const CLEAR_CREATED_CONNECTION = 'CLEAR_CREATED_CONNECTION';
export const WIZARD_GO_TO_FINAL_SCREEN = 'WIZARD_GO_TO_FINAL_SCREEN';
export const CLEAR_ALL_WIZARD_STATE = 'CLEAR_ALL_WIZARD_STATE';
export const WIZARD_SET_APP_TYPE = 'WIZARD_SET_APP_TYPE';
export const WIZARD_CONNECT_COLLABORATION_TOOL = 'WIZARD_CONNECT_COLLABORATION_TOOL';
export const FETCH_APP_TYPES = 'FETCH_APP_TYPES';

// Actions associated with Connections
export const FETCH_CONNECTIONS = 'FETCH_CONNECTIONS';
export const FETCH_CONNECTION = 'FETCH_CONNECTION';
export const ENABLE_CONNECTION = 'ENABLE_CONNECTION';
export const SET_CLICKED_CONNECTOR = 'SET_CLICKED_CONNECTOR';
export const SET_LATEST_OAUTH_REDIRECT_LINK = 'SET_LATEST_OAUTH_REDIRECT_LINK';
export const DISABLE_CONNECTOR = 'DISABLE_CONNECTOR';
export const CLEAR_DISABLE_CONNECTOR_RESPONSE = 'CLEAR_DISABLE_CONNECTOR_RESPONSE';
export const FETCH_ACTION_FIELDS = 'FETCH_ACTION_FIELDS';
export const FETCH_ACTION_METAFIELDS = 'FETCH_ACTION_METAFIELDS';
export const SAVE_ACTION_FIELDS = 'SAVE_ACTION_FIELDS';

// Actions associated with Profile screen
export const SETUP_TOTP = 'SETUP_TOTP';
export const SETUP_SMS = 'SETUP_SMS';
export const SETUP_MFA_SMS_ERROR = 'SETUP_MFA_SMS_ERROR';
export const ENTER_VERIFICATION_MFA_CODE = 'ENTER_VERIFICATION_MFA_CODE';
export const SETUP_MFA_ERROR = 'SETUP_MFA_ERROR';
export const SETUP_MFA_INFO = 'SETUP_MFA_INFO';
export const UPDATE_USER_ATTR = 'UPDATE_USER_ATTR';
export const UPDATE_USER_ATTR_ERROR = 'UPDATE_USER_ATTR';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const RESEND_SUBMIT_SMS_VERIFICATION_CODE = 'RESEND_SUBMIT_SMS_VERIFICATION_CODE';
export const FETCH_MY_CONTACTS = 'FETCH_MY_CONTACTS';
export const REINVITE_USER = 'REINVITE_USER';
export const CHANGE_USER_ROLE = 'CHANGE_USER_ROLE';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const INVITE_USER_PENDING = 'INVITE_USER_PENDING';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_FAILURE = 'INVITE_USER_FAILURE';

// Dashboard
export const FETCH_FAILED = 'FETCH_FAILED';
export const FETCH_WIDGET_DATA = 'FETCH_WIDGET_DATA';
export const UPDATE_WIDGET_DATA = 'UPDATE_WIDGET_DATA';
export const UPDATE_WIDGET_FROM_TO_DATES = 'UPDATE_WIDGET_FROM_TO_DATES';

// FAQ CMS
export const GET_QUESTIONS_TO_REVIEW = 'GET_QUESTIONS_TO_REVIEW';
export const GET_QUESTIONS_BY_USER = 'GET_QUESTIONS_BY_USER';
export const ACCEPT_QUESTION = 'ACCEPT_QUESTION';
export const REJECT_QUESTION = 'REJECT_QUESTION';
export const CLEAR_FAQ_CMS_STATE = 'CLEAR_FAQ_CMS_STATE';
export const SUBMIT_NEW_QUESTION = 'SUBMIT_NEW_QUESTION';
export const UPLOAD_FAQ_CMS_IMAGE = 'UPLOAD_FAQ_CMS_IMAGE';
export const GET_SUBJECT_AREAS = 'GET_SUBJECT_AREAS';
export const MARK_SUBMITTED_FAQ_FOR_DELETION = 'MARK_SUBMITTED_FAQ_FOR_DELETION';
export const DELETE_FAQ = 'DELETE_FAQ';
export const REJECT_FAQ_DELETION_SUGGESTION = 'REJECT_FAQ_DELETION_SUGGESTION';
export const GET_FAQ_CMS_SETTINGS = 'GET_FAQ_CMS_SETTINGS';
export const SAVE_FAQ_CMS_SETTINGS = 'SAVE_FAQ_CMS_SETTINGS';
export const LOAD_QUESTION_SUGGESTIONS = 'LOAD_QUESTION_SUGGESTIONS';
export const EDIT_QUESTION = 'EDIT_QUESTION';

// actions associated with theme layout
export const SET_THEME_SETTINGS = 'SET_THEME_SETTINGS';

// actions associated with config
export const CHANGE_QUARTAL = 'CHANGE_QUARTAL';
export const FETCH_QUARTAL = 'FETCH_QUARTAL';
export const FETCH_CONFIG_SETTINGS = 'FETCH_CONFIG_SETTINGS';
export const FETCH_USER_CONFIG_SETTINGS = 'FETCH_USER_CONFIG_SETTINGS';
export const UPDATE_CONFIG_SETTINGS = 'UPDATE_CONFIG_SETTINGS';
export const UPDATE_USER_CONFIG_SETTINGS = 'UPDATE_USER_CONFIG_SETTINGS';
export const RESET_CONFIG_SETTINGS_STATUS = 'RESET_CONFIG_SETTINGS_STATUS';
export const UPDATE_GLOBAL_CONFIG_SETTINGS = 'UPDATE_GLOBAL_CONFIG_SETTINGS';

// chatbot actions
export const TOGGLE_CHATBOT_RIGHT_SIDEBAR = 'TOGGLE_CHATBOT_RIGHT_SIDEBAR';
export const CHATBOT_SESSION_ID = 'CHATBOT_SESSION_ID';
export const UPDATE_PROMPT = 'UPDATE_PROMPT';
export const FETCH_PROMPT = 'FETCH_PROMPT';
export const BOT_MESSAGE_LOADING = 'BOT_MESSAGE_LOADING';
export const SAVE_CHAT_HISTORY = 'SAVE_CHAT_HISTORY';
export const HISTORY_LOADING = 'HISTORY_LOADING';
export const SET_CHAT_SESSION = 'SET_CHAT_SESSION';
export const SET_IS_STREAMING = 'SET_IS_STREAMING';
export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const SET_AGENT_LIST = 'SET_AGENT_LIST';

// common actions
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const RESET_CONFIG_STATUS = 'RESET_CONFIG_STATUS';
export const SET_ERROR = 'SET_ERROR';

// superadmin actions
export const GET_TOOLS = 'GET_TOOLS';
export const GET_TENANTS = 'GET_TENANTS';
export const GET_USERS = 'GET_USERS';
export const GET_ALL_AGENTS = 'GET_ALL_AGENTS';
export const GET_AGENTS = 'GET_AGENTS';
export const GET_AGENT_TYPES = 'GET_AGENT_TYPES';
export const GET_MODEL_TYPES = 'GET_MODEL_TYPES';
export const CREATE_AGENT = 'CREATE_AGENT';
export const CLEAR_STATUS = 'CLEAR_STATUS';
export const GET_AGENT_BY_ID = 'GET_AGENT_BY_ID';
export const CLEAR_AGENT = 'CLEAR_AGENT';
export const UPDATE_AGENT = 'UPDATE_AGENT';
export const DELETE_AGENT = 'DELETE_AGENT';
export const GET_CONVERSATION_LIST = 'GET_CONVERSATION_LIST';
export const RESET_CONVERSATION_LIST = 'RESET_CONVERSATION_LIST';
export const GET_MESSAGE_HISTORY = 'GET_MESSAGE_HISTORY';
export const APPEND_MESSAGE_HISTORY = 'APPEND_MESSAGE_HISTORY';
export const APPEND_CONVERSATION_LIST = 'APPEND_CONVERSATION_LIST';

// document manager actions
export const GET_SOURCES = 'GET_SOURCES';
export const REMOVE_DOCUMENT_STATUS = 'REMOVE_DOCUMENT_STATUS';
export const SET_FILES_FOR_UPLOAD = 'SET_FILES_FOR_UPLOAD';
export const SET_FILES_PROGRESS = 'SET_FILES_PROGRESS';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const FETCH_FILES = 'FETCH_FILES';
export const TOGGLE_LOADER = 'TOGGLE_LOADER';
export const SAVE_META = 'SAVE_META';
export const DELETE_FILE_META = 'DELETE_FILE_META';
export const UPDATE_CONTENT_STATUSES = 'UPDATE_FILE_STATUSES';
export const DELETE_FILE_S3 = 'DEL_S3';
export const SET_ACTIVE_FILE_INDEX = 'SET_ACTIVE_FILE_INDEX';
export const GET_AUTH_URL = 'GET_AUTH_URL';
export const GET_AUTH_TOKEN = 'GET_AUTH_TOKEN';
export const REDEEM_AUTH_CODE = 'REDEEM_AUTH_CODE';
export const CLEAR_APP_AUTH = 'CLEAR_APP_AUTH';
export const DELETE_TOKEN = 'DELETE_TOKEN';
export const TOGGLE_UPLOAD_TYPE = 'TOGGLE_UPLOAD_TYPE';
export const TOGGLE_CLASS_MODAL = 'TOGGLE_CLASS_MODAL';
export const DOC_MANAGER_SLICE = 'documentManagerSlice';
export const CHATBOT_SLICE = 'chatbotSlice';
export const COMMON_SLICE = 'commonSlice';
export const CLEAR_LAST_SAVED = 'clearLastSaved';
export const ASSIGN_SCHEMA = 'assignSchema';
export const CREATE_SCHEMA = 'createSchema';
export const GET_SCHEMAS = 'getSchemas';
export const CLEAR_SCHEMA_STATUSES = 'clearSchemaStatuses';
export const CLASSIFY_FILES = 'classifyFiles';
export const CLASSIFY_FILES_ERROR = 'classifyFilesError';
export const CLASSIFY_FILES_PENDING = 'classifyFilesPending';
export const DELETE_FILE_META_PENDING = 'deleteFileMetaPending';
export const DELETE_FILE_META_NOUPDATE = 'deleteFileMetaNoUpdate';
export const DELETE_FILE_META_ERROR = 'deleteFileMetaError';
export const CREATE_SCHEMA_ERROR = 'createSchemaError';
export const EXTRACTION_PIPELINE = 'pipelineExtraction';
export const EXTRACTION_PIPELINE_ERROR = 'pipelineExtractionError';
export const EXTRACTION_PIPELINE_PENDING = 'pipelineExtractionPending';
export const ASSIGN_PIPELINE = 'pipelineAssign';
export const ASSIGN_PIPELINE_ERROR = 'pipelineAssignError';
export const ASSIGN_PIPELINE_PENDING = 'pipelineAssignPending';
