// ----------------------------------------------------------------------

export default function Accordion(theme) {
	return {
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					'&.Mui-expanded': {
						backgroundColor: theme.palette.background.neutral
					}
				}
			}
		}
	};
}
