import { merge } from 'lodash';
import MuiButton from './MuiButton';
import MuiChip from './MuiChip';
import MuiFab from './MuiFab';
import MuiFilledInput from './MuiFilledInput';
import MuiIconButton from './MuiIconButton';
import MuiInput from './MuiInput';
import MuiLinearProgress from './MuiLinearProgress';
import MuiListItemIcon from './MuiListItemIcon';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiSvgIcon from './MuiSvgIcon';
import MuiTab from './MuiTab';
import MuiTableCell from './MuiTableCell';
import MuiTableRow from './MuiTableRow';
import MuiTabs from './MuiTabs';
import MuiTable from './MuiTable';
import MuiPaper from './MuiPaper';
import MuiBackdrop from './MuiBackdrop';
import MuiCard from './MuiCard';
import MuiTooltip from './MuiTooltip';
import MuiTypography from './MuiTypography';
import MuiLists from './MuiLists';
import MuiContainer from './MuiContainer';
import MuiBreadcrumbs from './MuiBreadcrumbs';
import MuiControlLabel from './MuiControlLabel';
import MuiDialog from './MuiDialog';
import MuiButtonGroup from './MuiButtonGroup';
import MuiToggleButton from './MuiToggleButton';
import MuiAutocomplete from './MuiAutocomplete';
import MuiListItemButton from './MuiListItemButton';
import MuiToolbar from './MuiToolbar';
import MuiAlert from './MuiAlert';
import MuiAccordion from './MuiAccordion';

export default function ComponentsOverrides(theme) {
	return merge(
		MuiButton(theme),
		MuiChip(theme),
		MuiFab(theme),
		MuiFilledInput(theme),
		MuiIconButton(theme),
		MuiInput(theme),
		MuiLinearProgress(theme),
		MuiListItemIcon(theme),
		MuiOutlinedInput(theme),
		MuiSvgIcon(theme),
		MuiTab(theme),
		MuiTableCell(theme),
		MuiTableRow(theme),
		MuiTable(theme),
		MuiTabs(theme),
		MuiPaper(theme),
		MuiBackdrop(theme),
		MuiCard(theme),
		MuiTooltip(theme),
		MuiTypography(theme),
		MuiLists(theme),
		MuiContainer(theme),
		MuiBreadcrumbs(theme),
		MuiControlLabel(theme),
		MuiDialog(theme),
		MuiButtonGroup(theme),
		MuiToggleButton(theme),
		MuiAutocomplete(theme),
		MuiListItemButton(theme),
		MuiToolbar(theme),
		MuiAlert(theme),
		MuiAccordion(theme)
	);
}
