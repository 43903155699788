import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import CONFIGURATION from '../../app_configuration';

export const createPipeline = createAsyncThunk(
	'pipeline/createPipeline',
	async (pipeline, { rejectWithValue }) => {
		try {
			const response = await API.post(
				CONFIGURATION.AWS_API_NAME,
				'/content/create-new-pipeline',
				{
					response: true,
					body: {
						extraction_pipeline: { ...pipeline.extraction_pipeline, workflows: [] }
					}
				}
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data.detail);
		}
	}
);

export const updatePipeline = createAsyncThunk(
	'pipeline/updatePipeline',
	async (pipeline, { rejectWithValue }) => {
		try {
			const response = await API.put(CONFIGURATION.AWS_API_NAME, `/pipeline/${pipeline.id}`, {
				response: true,
				body: pipeline
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data.detail);
		}
	}
);

export const deletePipeline = createAsyncThunk(
	'pipeline/deletePipeline',
	async (pipelineId, { rejectWithValue }) => {
		try {
			await API.post(CONFIGURATION.AWS_API_NAME, `/content/remove-extraction-pipeline`, {
				body: {
					extraction_pipeline_id: pipelineId
				},
				response: true
			});
			return pipelineId;
		} catch (error) {
			return rejectWithValue(error.response.data.detail);
		}
	}
);

export const assignPipeline = createAsyncThunk(
	'pipeline/assignPipeline',
	async ({ files, pipelineId }, { rejectWithValue }) => {
		try {
			await API.post(CONFIGURATION.AWS_API_NAME, `/content/assign-pipeline`, {
				response: true,
				body: {
					document_ids: files.map(file => file.id),
					extraction_pipeline_id: pipelineId
				}
			});
			return files;
		} catch (error) {
			return rejectWithValue(error.response.data.detail);
		}
	}
);

export const getAllPipelines = createAsyncThunk(
	'pipeline/getAllPipelines',
	async (_, { rejectWithValue }) => {
		try {
			const response = await API.get(CONFIGURATION.AWS_API_NAME, '/content/get-pipelines', {
				response: true
			});
			return response.data.extraction_pipelines;
		} catch (error) {
			return rejectWithValue(error.response.data.detail);
		}
	}
);

export const createWorkflow = createAsyncThunk(
	'pipeline/createWorkflow',
	async (workflow, { rejectWithValue }) => {
		try {
			const response = await API.post(
				CONFIGURATION.AWS_API_NAME,
				'/content/create-new-extraction-schema',
				{
					response: true,
					body: workflow
				}
			);

			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data.detail);
		}
	}
);

export const getAllWorkflows = createAsyncThunk(
	'pipeline/getAllWorkflows',
	async (_, { rejectWithValue }) => {
		try {
			const response = await API.get(
				CONFIGURATION.AWS_API_NAME,
				'/content/get-extraction-schemas',
				{
					response: true
				}
			);
			return response.data.extraction_schemas;
		} catch (error) {
			return rejectWithValue(error.response.data.detail);
		}
	}
);

export const getExtractionResults = createAsyncThunk(
	'pipeline/getExtractionResults',
	async (documentId, { rejectWithValue }) => {
		try {
			const response = await API.post(
				CONFIGURATION.AWS_API_NAME,
				'/content/extraction-results',
				{
					response: true,
					body: {
						document_ids: [documentId]
					}
				}
			);
			console.log('response.data', response.data);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data.detail);
		}
	}
);

const initialState = {
	pipelines: [],
	sidebarOpen: false,
	loading: false,
	error: null
};

const pipelineSlice = createSlice({
	name: 'pipelineManager',
	initialState,
	reducers: {
		toggleSidebar: state => {
			state.sidebarOpen = !state.sidebarOpen;
		},
		setActiveFiles: (state, action) => {
			state.activeFiles = action.payload;
		},
		setCreatedPipeline: (state, action) => {
			state.createdPipeline = action.payload;
		},
		setCreatedWorkflows: (state, action) => {
			state.createdWorkflows = action.payload;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(createPipeline.pending, state => {
				state.loading = true;
				state.error = null;
				state.createdPipeline = null;
			})
			.addCase(createPipeline.fulfilled, (state, action) => {
				state.loading = false;
				state.createdPipeline = action.payload;
			})
			.addCase(createPipeline.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(updatePipeline.pending, state => {
				state.loading = true;
				state.error = null;
			})
			.addCase(updatePipeline.fulfilled, (state, action) => {
				state.loading = false;
				state.pipelines = state.pipelines.map(pipeline =>
					pipeline.id === action.payload.id ? action.payload : pipeline
				);
			})
			.addCase(updatePipeline.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(deletePipeline.pending, state => {
				state.deleting = true;
				state.error = null;
			})
			.addCase(deletePipeline.fulfilled, (state, action) => {
				state.deleting = false;
				state.pipelines = state.pipelines.filter(
					pipeline => pipeline.extraction_pipeline_id !== action.payload
				);
			})
			.addCase(deletePipeline.rejected, (state, action) => {
				state.deleting = false;
				state.error = action.payload;
			})
			.addCase(assignPipeline.pending, state => {
				state.loading = true;
				state.error = null;
				state.assignedDocs = null;
			})
			.addCase(assignPipeline.fulfilled, (state, action) => {
				state.loading = false;
				state.assignedDocs = action.payload;
			})
			.addCase(assignPipeline.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(getAllPipelines.pending, state => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getAllPipelines.fulfilled, (state, action) => {
				state.loading = false;
				state.pipelines = action.payload;
			})
			.addCase(getAllPipelines.rejected, state => {
				state.loading = false;
				state.error = true;
			})
			.addCase(createWorkflow.pending, state => {
				state.loading = true;
				state.error = null;
			})
			.addCase(createWorkflow.fulfilled, (state, action) => {
				state.loading = false;
				state.createdWorkflows = state.createdWorkflows
					? [...state.createdWorkflows, action.payload]
					: [action.payload];
			})
			.addCase(createWorkflow.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(getAllWorkflows.pending, state => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getAllWorkflows.fulfilled, (state, action) => {
				state.loading = false;
				state.workflows = action.payload;
			})
			.addCase(getAllWorkflows.rejected, state => {
				state.loading = false;
				state.error = true;
			})
			.addCase(getExtractionResults.pending, state => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getExtractionResults.fulfilled, (state, action) => {
				state.loading = false;
				state.extractionResults = action.payload;
			})
			.addCase(getExtractionResults.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	}
});

export const { toggleSidebar, setActiveFiles, setCreatedPipeline, setCreatedWorkflows } =
	pipelineSlice.actions;
export default pipelineSlice.reducer;
